<template>
  <div class="text-container">
    <span class="text">You have been logged out</span>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "logout",
  mounted() {
    axios
      .post("/api/v1/token/logout")
      .then(() => {
        this.logout();
      })
      .catch(() => {
        this.logout();
      });
  },
  methods: {
    logout() {
      this.$store.commit("removeToken");
      this.$store.commit("removeUserData");
      this.$store.commit("setAuthentication", false);
      this.$notify({
        title: "Logged Out!",
        body: "You have been successfully logged out!",
        icon: "",
        type: "success",
      });
      const toPath = this.$route.query.to || "/";
      this.$router.push(toPath);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  font-size: 1.1rem;
  font-weight: 300;
}
</style>
